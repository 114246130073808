<template>
  <div>
    <div class="row justify-content-md-center" v-cloak v-if="!state.loaded">
      <div class="col col-lg-2"></div>
      <div class="col-md-auto mt-20">
        <rotate-square5></rotate-square5>
      </div>
      <div class="col col-lg-2"></div>
    </div>
    <div class="card" v-else>
      <div class="card-body">
        <form @submit.prevent="submit" class="form-horizontal">
          <div class="form-body">
            <h3 class="box-title">Facilities Management</h3>
            <hr class="m-t-0 m-b-40">
            <div class="row">
              <div class="col-md-8">
                <Input v-model="payload.facilities" name="facilities" required type="text" label="Facilities"/>
              </div>
            </div>
          </div>
          <hr>
          <div class="form-actions">
            <div class="row">
              <div class="col-md-6">
                <div class="row">
                  <div class="col-md-offset-3 col-md-9">
                    <button type="submit" class="btn btn-success" title="Simpan">Submit</button>&nbsp;
                    <button type="button" @click="goBack()" class="btn btn-inverse" title="Cancel">Cancel</button>
                  </div>
                </div>
              </div>
              <div class="col-md-6"></div>
            </div>
          </div>
        </form>
      </div>
    </div>
  </div>
</template>

<script>
import { RotateSquare5 } from "vue-loading-spinner";
import Input from "@/components/input/input";
import Multiselect from "vue-multiselect";
import Upload from "@/components/input/upload.vue";
import axios from "axios";

export default {
  components: {
    Input,
    RotateSquare5,
    Upload,
    Multiselect
  },
  computed: {
    editMode() {
      return !!this.$route.params.id;
    },
    state() {
      return this.$store.state.facilities;
    },
    isInvalidBahasa() {
      return this.isTouched && this.payload.valueBahasa.length === 0;
    },
    isInvalidUnit() {
      return this.isTouched && this.payload.valueUnit.length === 0;
    },
    isMobile() {
      return this.$store.state.isMobile;
    }
  },
  data() {
    return {
      options: [
        "Select option",
        "Disable me!",
        "Reset me!",
        "Admin Dinas Komunikasi dan Informatika ( Kepala Seksi Dinas Komunikasi dan Informatika )",
        "label",
        "searchable"
      ],
      payload: {
        facilities: ""
      },
      isTouched: false,
      isDisabled: false
    };
  },
  async mounted() {
    this.initialize();
  },

  methods: {
    async initialize() {
      try {
        if (this.editMode) {
          axios.get(`/facilities/${this.$route.params.id}`).then(response => {
            const state = {
              loaded: true
            };
            this.$store.commit("facilities/STATE", state);
            this.edit(response.data.data);
          });
        } else {
          this.$store.dispatch("facilities/onAdd");
        }
      } catch (e) {
        this.handleApiError(e);
        this.error = e.message;
      }
    },
    edit(data) {
      this.payload = {
        facilities: data.name
      };
    },
    onTouch() {
      this.isTouched = true;
    },
    onChangeBahasa(value) {
      this.payload.valueBahasa = value;
      if (value.indexOf("Reset me!") !== -1) this.payload.valueBahasa = [];
    },
    onChangeUnit(value) {
      this.payload.valueUnit = value;
      if (value.indexOf("Reset me!") !== -1) this.payload.valueUnit = [];
    },
    onSelect(option) {
      if (option === "Disable me!") this.isDisabled = true;
    },
    goBack() {
      this.$store.dispatch("facilities/onCancel");
    },
    submit() {
      const payload = {
        name: this.payload.facilities
      };
      const data = JSON.stringify(payload);
      this.$validator.validateAll().then(success => {
        if (success) {
          if (this.editMode) {
            this.$store.dispatch("facilities/submitEdit", {
              data,
              id: this.$route.params.id
            });
          } else {
            this.$store.dispatch("facilities/submitAdd", data);
          }
        }
      });
    }
  }
};
</script>
<style src="vue-multiselect/dist/vue-multiselect.min.css"></style>
<style>
.multiselect__tags {
  border: 1px solid #ced4da !important;
}
.invalid .multiselect__tags {
  border-color: #f04124 !important;
  padding: 5px 40px 0 8px !important;
}
.invalid .typo__label {
  color: #f04124;
  font-size: 0.8125rem;
}
.multiselect {
  color: #54667a !important;
}
.multiselect__select {
  height: 41px !important;
}
</style>

